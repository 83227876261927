import React from 'react';

function Introduce(props) {
    return (
        <div>
                  <h1>안녕하세요 자기 소개 페이지 입니다.</h1> 
        </div>
    );
}

export default Introduce;