import './App.css';
import Router from './common/Router';
function App() {
  return (
    <div className="App">
      {/* <Header></Header> */}
      {/* <Home></Home> */}
      <Router/>
      {/* <Test></Test> */}
    </div>
  );
}

export default App;
