import React from 'react';

function Home(props) {
    return (
        <div>
           <h1>안녕하세요 홈 페이지 입니다.</h1> 
        </div>
    );
}

export default Home;